























































import { defineComponent } from "@vue/composition-api";
import FormBase from "@/components/FormBase.vue";
import FormItem from "@/components/FormItem.vue";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { getAuth } from "@/plugins/firebase";

type DataType = {
  email: string | null;
  password: string;
  passwordConfirm: string;
  error: string | null;
  successMessage: string | null;
  isLoading: boolean;
  oobCode: string;
};

const invalidActionMessage = "Invalid action code. Please request a new instructions e-mail.";

export default defineComponent({
  name: "ResetPasswordPage",
  components: { FormBase, FormItem },
  data: (): DataType => ({
    email: null,
    password: "",
    passwordConfirm: "",
    error: null,
    successMessage: null,
    isLoading: false,
    oobCode: "",
  }),
  mounted() {
    this.oobCode = this.$route.query.oobCode as string;
  },
  methods: {
    async onSubmit() {
      this.error = null;
      this.isLoading = true;
      const auth = getAuth();

      await verifyPasswordResetCode(auth, this.oobCode)
        .then(email => {
          this.email = email;

          confirmPasswordReset(auth, this.oobCode, this.password).then(() => {
            this.$notify.success({
              title: "Operation Successful",
              message: "Password has been reset successfully.",
              position: "bottom-left",
            });
            this.$router.replace({ name: "LoginPage" });
          })
            .catch(() => {
              this.$message.error(invalidActionMessage);
            });
        })
        .catch(() => {
          this.$message.error(invalidActionMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onAlertClose() {
      this.error = null;
    },
  },
});
